import { createSlice } from '@reduxjs/toolkit';
import { getProtheseById, getProtheses, getRealisedProtheses, getRequestedProtheses, getSearchedProtheses, } from './protheseThunk';
const initialState = {
    patient: '',
    dents: [],
    duration: '',
    price: 0,
    allProtheses: [],
    total: 0,
    realisedProtheses: 0,
    requestedProtheses: 0,
    loading: false,
    done: false,
    files: [],
    oneData: undefined,
    error: false,
    isUpdate: false,
    TreatmentId: undefined,
    ProtheseId: undefined,
    isUpdate2: false,
    pass: false,
    forwardCommandView: false,
    commandId: undefined,
    description: '',
    physicalFootprint: false,
    digitalFootprint: false,
};
const dentSlice = createSlice({
    name: 'dent',
    initialState,
    reducers: {
        clearTData: (state) => {
            if (!state.pass) {
                state.dents = [];
                state.duration = '';
                state.price = 0;
                state.allProtheses = [];
                state.done = false;
                state.files = [];
                state.isUpdate = false;
                state.TreatmentId = undefined;
                state.ProtheseId = undefined;
                state.commandId = undefined;
                state.isUpdate2 = false;
                state.forwardCommandView = false;
                state.description = '';
            }
            else {
                state.pass = false;
            }
        },
        confirmStep: (state) => {
            state.done = true;
        },
        confirmPass: (state) => {
            state.pass = true;
        },
        resetStep: (state) => {
            state.done = false;
        },
        setUpdate: (state) => {
            state.isUpdate = true;
            state.isUpdate2 = true;
        },
        setForwardCommand: (state, action) => {
            state.forwardCommandView = true;
            state.commandId = action.payload;
        },
        setUpdateProthese: (state) => {
            state.isUpdate2 = true;
        },
        setTreatment: (state, action) => {
            state.TreatmentId = action.payload;
        },
        setProtheseId: (state, action) => {
            state.ProtheseId = action.payload;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        insertNum: (state, action) => {
            state.digitalFootprint = action.payload;
        },
        insertPhy: (state, action) => {
            state.physicalFootprint = action.payload;
        },
        removeUpdate: (state) => {
            state.isUpdate = false;
        },
        setDents: (state, action) => {
            state.dents = action?.payload;
        },
        insertPatient: (state, action) => {
            state.patient = action.payload;
        },
        insertFiles: (state, action) => {
            state.files = action.payload;
        },
        insertPrice: (state, action) => {
            state.price = action.payload;
        },
        insertDuration: (state, action) => {
            state.duration = action.payload;
        },
        updateTeeth: (state) => {
            const newTeeth = state.dents.map((teeth) => {
                if (teeth.name !== '' && teeth.type !== '') {
                    return { ...teeth, confirmed: true };
                }
                return teeth;
            });
            state.dents = newTeeth;
        },
        insertRemark: (state, action) => {
            const { remark, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, remarks: remark };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertColor: (state, action) => {
            const { color, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, color: color };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertOmbre: (state, action) => {
            const { ombre, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, ombre: ombre };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertDentPrice: (state, action) => {
            const { price, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, price: price };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertDentType: (state, action) => {
            const { types, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, type: types };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        removeDentType: (state, action) => {
            const { name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, type: '' };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertDentMaterial: (state, action) => {
            const { materials, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, material: materials };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertDentArticle: (state, action) => {
            const { article, name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, article: article };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        setDentAsConfirmed: (state, action) => {
            const { name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, confirmed: true };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        setDentAsNotConfirmed: (state, action) => {
            const { name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent, confirmed: false };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        setColorToAllTheeth: (state, action) => {
            const { color, ombre } = action.payload;
            if (color) {
                const allTheeth = state.dents.map((dent) => ({ ...dent, color, ombre: ombre }));
                state.dents = allTheeth;
            }
        },
        clearCurrentMaterials: (state, action) => {
            const { name } = action.payload;
            const dent = state.dents.find((dent) => dent.name === name);
            if (dent) {
                const updatedDent = { ...dent };
                const index = state.dents.findIndex((dent) => dent.name === name);
                state.dents[index] = updatedDent;
            }
        },
        insertDent: (state, action) => {
            const { name } = action.payload;
            if (!state.dents.some((dent) => dent.name === name)) {
                state.dents.push(action.payload);
            }
        },
        updateDent: (state, action) => {
            const { name, updatedDent } = action.payload;
            const dentToUpdate = state.dents.find((dent) => dent.name === name);
            if (dentToUpdate) {
                Object.assign(dentToUpdate, updatedDent);
            }
        },
        deleteDent: (state, action) => {
            state.dents = state.dents.filter((dent) => dent.name !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProtheses.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getProtheses.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allProtheses = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getProtheses.rejected, (state, { payload }) => {
            state.loading = false;
        })
            .addCase(getSearchedProtheses.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getSearchedProtheses.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allProtheses = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getSearchedProtheses.rejected, (state, { payload }) => {
            state.loading = false;
        })
            .addCase(getProtheseById.pending, (state, actions) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getProtheseById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getProtheseById.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getRealisedProtheses.pending, (state, actions) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getRealisedProtheses.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.realisedProtheses = payload?.data?.realisedProtheses;
        })
            .addCase(getRealisedProtheses.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getRequestedProtheses.pending, (state, actions) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getRequestedProtheses.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.requestedProtheses = payload?.data?.requestedProtheses;
        })
            .addCase(getRequestedProtheses.rejected, (state) => {
            state.error = true;
            state.loading = false;
        });
    },
});
export const { insertPatient, insertPrice, insertDuration, insertDent, updateDent, deleteDent, insertRemark, insertDentPrice, setDentAsConfirmed, clearCurrentMaterials, setDentAsNotConfirmed, insertDentType, insertDentArticle, removeDentType, insertColor, insertDentMaterial, clearTData, confirmStep, insertFiles, resetStep, insertOmbre, setUpdate, removeUpdate, setTreatment, setDents, setUpdateProthese, setProtheseId, confirmPass, setForwardCommand, setDescription, insertNum, insertPhy, setColorToAllTheeth, updateTeeth, } = dentSlice.actions;
export default dentSlice.reducer;
